/* eslint-disable */

import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import utils from '../utils/index';

// Full config:  https://github.com/axios/axios#request-config
if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = '/api';
}
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
const urlParam = utils.getParam();
const _axios = axios.create(config);
_axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
_axios.interceptors.request.use(
  (config) => {
  // Do something before request is sent
    if (config) {
      config.data = config.data || {};
      if (urlParam.token) {
        config.data.token = urlParam.token;
      }
      if (config.method === 'get') {
        config.params = config.data;
      } else if (!config.formData && !config.json) {
        config.data = qs.stringify(config.data);
      }

    }
    return config;
  }
  , error =>
  // Do something with request error
    Promise.reject(error),
);

_axios.interceptors.response.use((response) => {
  // Do something with response data
  if (response && response.data) {
    if (response.data) {
      return response.data;
    }
  }
  return response;
}, error =>
// Do something with response error
  Promise.reject(error));

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
