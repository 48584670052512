<template>
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter"></div>
          <button class="pswp__button pswp__button--close" title="关闭"></button>
          <button class="pswp__button pswp__button--fs" title="切换全屏"></button>
          <button class="pswp__button pswp__button--zoom" title="放大/缩小"></button>
          <button class="pswp__button pswp__button--rotate" title="旋转" @click="imgRotateFn"></button>
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>
        <button class="pswp__button pswp__button--arrow--left" title="上一张" @click="initRotateFn"></button>
        <button class="pswp__button pswp__button--arrow--right" title="下一张" @click="initRotateFn"></button>
        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import './skin.css';

export default {
  data() {
    return {
      xjAngle: 0,
    };
  },
  methods: {
    imgRotateFn() {
      this.xjAngle += 90;
      const imgNode = document.getElementsByClassName('pswp__img');
      for (let i = 0; i < imgNode.length; i += 1) {
        imgNode[i].style.WebkitTransform = `rotate(${this.xjAngle}deg)`;
      }
    },
    initRotateFn() {
      this.xjAngle = 0;
      const imgNode = document.getElementsByClassName('pswp__img');
      for (let i = 0; i < imgNode.length; i += 1) {
        imgNode[i].style.WebkitTransform = `rotate(${this.xjAngle}deg)`;
      }
    },
  },
};

</script>
<style lang="scss">
  .pswp {
    z-index: 9999;
    .pswp__caption__center {
      text-align: center;
      max-width: 60%;
    }
  }
</style>
