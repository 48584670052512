/* eslint-disable */

import previewComponent from './preview.vue';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

let $preview;
const vuePhotoPreview = {
  install(Vue, opts) {
    const Preview = Vue.extend(previewComponent);
    opts = opts || {
      mainClass: 'pswp--minimal--dark',
      barsSize: {
        top: 0,
        bottom: 0,
      },
      captionEl: true,
      fullscreenEl: false,
      shareEl: false,
      bgOpacity: 0.8,
      tapToClose: true,
      tapToToggleControls: false,
    };
    if (!$preview) {
      $preview = new Preview({ el: document.createElement('div') });
      document.body.appendChild($preview.$el);
    }
    let eventName,
      eventCallback;
    Vue.prototype.$preview = {
      self: null,
      on: (name, callback) => {
        eventName = name;
        eventCallback = callback;
      },
    };
    Vue.mixin({
      data() {
        return {
          galleryElements: null,
        };
      },
      methods: {
        $previewRefresh() {
          setTimeout(() => {
            this.galleryElements = document.querySelectorAll('img[preview]');
            for (let i = 0, l = this.galleryElements.length; i < l; i++) {
              this.galleryElements[i].setAttribute('data-pswp-uid', i + 1);
              this.galleryElements[i].onclick = this.onThumbnailsClick;
            }
          }, 200);
        },
        /**
         * 手动通过图片地址进行预览
         * @param urls 图片地址
         * @param index 图片位置
         * @return {Promise<undefined>}
         */
        $previewByImgUrl(urls, index) {
          let imgArr = [];
          if (Array.isArray(urls)) {
            imgArr = Array.from(urls, (url) => {
              const img = new Image();
              img.src = url;
              return img;
            });
          } else {
            const img = new Image();
            img.src = urls;
            imgArr = [img];
          }
          const imgIndex = index || 0;
          return this.openPhotoSwipe(imgIndex, imgArr);
        },
        onThumbnailsClick(e) {
          if (Vue.prototype.$preview.self) {
            return;
          }
          e = e || window.event;
          e.preventDefault ? e.preventDefault() : e.returnValue = false;

          const eTarget = e.target || e.srcElement;


          let thumbElements;
          const group = eTarget.getAttribute('preview');
          if (group) {
            thumbElements = document.querySelectorAll(`img[preview="${group}"]`);
          } else {
            thumbElements = document.querySelectorAll('img[preview]');
          }
          const clickedGallery = thumbElements;

          let index;

          for (let i = 0; i < clickedGallery.length; i++) {
            if (clickedGallery[i] === eTarget) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            this.openPhotoSwipe(index, clickedGallery);
            this.$emit('preview-open', e, eTarget.src);
          }
          return false;
        },
        async openPhotoSwipe(index, galleryElement, disableAnimation, fromURL) {
          if (Vue.prototype.$preview.self) {
            return;
          }
          var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

          var items = await this.parseThumbnailElements(galleryElement);
          options = {

            // galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn() {
              let thumbnail = items[index].el,
                pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                rect = thumbnail.getBoundingClientRect();
              console.log(rect);
              return {
                x: rect.left,
                y: rect.top + pageYScroll,
                w: rect.width,
              };
            },

            addCaptionHTMLFn(item, captionEl, isFake) {
              if (!item.title) {
                captionEl.children[0].innerText = '';
                return false;
              }
              captionEl.children[0].innerHTML = item.title;
              return true;
            },
            showHideOpacity: true,
            history: false,
            shareEl: false,
            maxSpreadZoom: 2,
            getDoubleTapZoom(isMouseClick, item) {
              const contentWidth = (window.innerWidth || document.body.clientWidth || 0) * 0.9;
              const imgWidth = item.w || item.el.naturalWidth || 1;
              const maxZoomLevel = contentWidth / imgWidth;
              let targetZoomLevel = 1;
              if (isMouseClick) {
                targetZoomLevel = 2;
              } else {
                targetZoomLevel = item.initialZoomLevel < 0.7 ? 1 : 1.5;
              }
              targetZoomLevel = targetZoomLevel < maxZoomLevel ? targetZoomLevel : maxZoomLevel;
              return targetZoomLevel;
            },

          };

          if (fromURL) {
            if (options.galleryPIDs) {
              // parse real index when custom PIDs are used
              // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
              for (let j = 0; j < items.length; j++) {
                if (items[j].pid == index) {
                  options.index = j;
                  break;
                }
              }
            } else {
              options.index = parseInt(index, 10) - 1;
            }
          } else {
            options.index = parseInt(index, 10);
          }

          // exit if index not found
          if (isNaN(options.index)) {
            return;
          }
          options = this.extend(options, opts);

          if (disableAnimation) {
            options.showAnimationDuration = 0;
          }

          // Pass data to PhotoSwipe and initialize it
          gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
          Vue.prototype.$preview.self = gallery;
          // see: http://photoswipe.com/documentation/responsive-images.html
          let realViewportWidth,
            useLargeImages = false,
            firstResize = true,
            imageSrcWillChange;

          gallery.listen('beforeResize', () => {
            let dpiRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
            dpiRatio = Math.min(dpiRatio, 2.5);
            realViewportWidth = gallery.viewportSize.x * dpiRatio;

            if (realViewportWidth >= 1200 || (!gallery.likelyTouchDevice && realViewportWidth > 800) || screen.width > 1200) {
              if (!useLargeImages) {
                useLargeImages = true;
                imageSrcWillChange = true;
              }
            } else if (useLargeImages) {
              useLargeImages = false;
              imageSrcWillChange = true;
            }

            if (imageSrcWillChange && !firstResize) {
              gallery.invalidateCurrItems();
            }

            if (firstResize) {
              firstResize = false;
            }

            imageSrcWillChange = false;
          });

          gallery.listen('gettingData', (index, item) => {
            if (item.el.getAttribute('large')) {
              item.src = item.o.src;
              item.w = item.o.w;
              item.h = item.o.h;
            } else {
              item.src = item.m.src;
              item.w = item.m.w;
              item.h = item.m.h;
            }
          });
          gallery.listen('close', () => {
            $preview.xjAngle = 0;
            setTimeout(function () {
              Vue.prototype.$preview.self = null;
            }, 1);
          });
          gallery.listen(eventName, eventCallback);
          gallery.init();
          $preview.$el.classList = `${$preview.$el.classList} pswp--zoom-allowed`;
        },
        parseThumbnailElements(thumbElements) {
          return new Promise((resolve) => {
            let items = [],
              el,
              load = 0,
              item;
            item = {};
            for (var i = 0; i < thumbElements.length; i++) {
              el = thumbElements[i];

              // include only element nodes
              if (el.nodeType !== 1) {
                continue;
              }

              if (typeof el.naturalWidth === 'undefined') {　　 // IE 6/7/8
                var i = new Image();
                i.src = el.src.replace('&key=thumb', '');
                var rw = i.width;
                var rh = i.height;
              } else {　　 // HTML5 browsers
                var rw = el.naturalWidth;
                var rh = el.naturalHeight;
              }
              getImage(i);
              var count = 0;
              function getImage(index) {
                const l = new Image();
                l.src=el.src.replace('&key=thumb', '')
                l.text = el.getAttribute('preview-text');
                l.author = el.getAttribute('data-author');
                l.onload = function () {
                  item = {
                    title: l.text,
                    el,
                    src: l.src,
                    w: l.naturalWidth,
                    h: l.naturalHeight,
                    author: l.author,
                    o: {
                      src: l.src,
                      w: l.naturalWidth,
                      h: l.naturalHeight,
                    },
                    m: {
                      src: l.src,
                      w: l.naturalWidth,
                      h: l.naturalHeight,
                    },
                  };
                  items[index] = item;
                  count++;
                  if (count == thumbElements.length) {
                    console.log(items);
                    resolve(items);
                  }
                };
              }
            }
          });

        },
        extend(o1, o2) {
          for (const prop in o2) {
            o1[prop] = o2[prop];
          }
          return o1;
        },
        initPhotoPreview(gallerySelector) {
          this.galleryElements = document.querySelectorAll(gallerySelector);
          for (let i = 0, l = this.galleryElements.length; i < l; i++) {
            this.galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            this.galleryElements[i].onclick = this.onThumbnailsClick;
          }
        },
      },
      mounted() {
        this.initPhotoPreview('img[preview]');
      },
    });
  },
};

export default vuePhotoPreview;

if (typeof window !== 'undefined' && !window.vuePhotoPreview) {
  window.vuePhotoPreview = vuePhotoPreview;
}
