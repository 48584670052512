import Vue from 'vue';
import {
  Pagination,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
  Icon,
  Image,
  Loading,
  Message,
} from 'element-ui';
import '@/scss/element-variables.scss';

Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Image);

Vue.use(Loading.directive);

Vue.prototype.$loading = () => Loading.service({ body: true, background: 'transparent' });
Vue.prototype.$message = Message;
