const apiPath = '/cmsServer/admin/api';
const cms = {
  graphQL: {
    // 查询首页Banner
    queryIndexBanners: {
      url: apiPath,
      query: 'query allIndexBanners{allIndexBanners(orderBy: "order_ASC" where: {state: published}){id name text image{publicUrl}}}',
      column: 'allIndexBanners',
    },
    // 查询案例展示
    queryFeatures: {
      url: apiPath,
      query: 'query allFeatures{allFeatures(orderBy: "order_ASC" where: {state: published}){id name desc image{publicUrl}}}',
      column: 'allFeatures',
    },
    // 查询视频演示
    queryVideos: {
      url: apiPath,
      query: 'query allVideos{allVideos(orderBy: "order_ASC" where: {state: published}){id name desc backgroundImage{publicUrl} video{publicUrl}}}',
      column: 'allVideos',
    },
    // 查询案例展示
    queryDemos: {
      url: apiPath,
      query: 'query allDemos{allDemos(orderBy: "order_ASC" where: {state: published}){id name desc image{publicUrl}}}',
      column: 'allDemos',
    },
    // 查询帮助文档
    queryHelps: {
      url: apiPath,
      query: 'query allQuestions{allHelps(orderBy: "order_ASC" where: {state: published type:{accessRole: public}}){id name type{id name desc order accessRole} doc{publicUrl}}}',
      column: 'allHelps',
    },

    // 查询帮助文档详情
    // variables: $id -- 文章ID
    queryHelpDetail: {
      url: apiPath,
      query: 'query Help($id: ID!){Help(where:{id:$id}){id name type{name id} author content1 content2 content3 content4 content5 media1{publicUrl} media2{publicUrl} media3{publicUrl} media4{publicUrl} doc{originalFilename publicUrl}}}',
      column: 'Help',
    },
    // 查询外部页面列表
    // $count -- 数量 $skip -- 从第几条开始
    queryOuterPageList: {
      url: apiPath,
      query: 'query allOuterPages($count: Int,$skip: Int){ allOuterPages(first: $count skip: $skip where:{state: published}) {id name author summary } _allOuterPagesMeta(where: {state:published}) {count}}',
      column: 'allOuterPages',
      metaColumn: '_allOuterPagesMeta',
    },
    // 查询外部页面详情
    // variables: $id -- 文章ID
    queryOuterPageDetail: {
      url: apiPath,
      query: 'query OuterPage($id: ID!){ OuterPage(where:{id:$id}) {id name author summary content }}',
      column: 'OuterPage',
    },
    // 查询外部海报列表
    // $count -- 数量 $skip -- 从第几条开始
    queryOuterPosterList: {
      url: apiPath,
      query: 'query allOuterPosters($count: Int,$skip: Int){ allOuterPosters(first: $count skip: $skip where:{state: published}) {id name author summary poster{publicUrl} } _allOuterPostersMeta(where: {state:published}) {count}}',
      column: 'allOuterPosters',
      metaColumn: '_allOuterPostersMeta',
    },
    // 查询外部海报详情
    // variables: $id -- 文章ID
    queryOuterPosterDetail: {
      url: apiPath,
      query: 'query OuterPoster($id: ID!){ OuterPoster(where:{id:$id}) {id name author summary poster{publicUrl} link }}',
      column: 'OuterPoster',
    },
  },
};
module.exports = cms;
