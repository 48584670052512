const date = {
  _timeDifference: 0,
  formatDate(dateSrc, fmt) {
    const dateObj = new Date(date.getMillisecondsByDate(dateSrc));
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (`${dateObj.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    const o = {
      'M+': dateObj.getMonth() + 1,
      'd+': dateObj.getDate(),
      'h+': dateObj.getHours(),
      'm+': dateObj.getMinutes(),
      's+': dateObj.getSeconds(),
    };
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const k in o) {
      const str = `${o[k]}`;
      if (new RegExp(`(${k})`).test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : str.padStart(2, '0'));
      }
    }
    return fmt;
  },
  /**
   * 根据日期获取对应的时间毫秒数，并兼容老版本和safari浏览器
   * @param dateSrc 时间字符串
   * @return {number} 时间毫秒数
   */
  getMillisecondsByDate(dateSrc) {
    if (Number.isInteger(dateSrc) || !dateSrc) {
      return Math.floor(dateSrc) || 0;
    }
    if (date.isDate(dateSrc.replace(/-/g, '/'))) {
      return new Date(dateSrc.replace(/-/g, '/')).getTime();
    }
    let isSafari = false;
    if (!date.isDate(dateSrc) && date.isDate(dateSrc.replace(' ', 'T'))) {
      isSafari = true;
      dateSrc = dateSrc.replace(' ', 'T');
    }
    if (date.isDate(dateSrc)) {
      if (isSafari) {
        return new Date(dateSrc).getTime() - 28800000;
      }
      return new Date(dateSrc).getTime();
    }

    return 0;
  },

  getRealMilliseconds() {
    return new Date().getTime() + date._timeDifference;
  },

  /**
   * 根据时间获取星期
   * @param time
   * @returns {string}
   */
  getWeek(time) {
    if (date.isDate(time)) {
      const dateObj = new Date(time);
      const weekDay = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      return weekDay[dateObj.getDay()];
    }
    return '';
  },

  isDate(dateSrc) {
    return !!(dateSrc && new Date(dateSrc).toString() !== 'Invalid Date');
  },

  correctDate: (currentMilliseconds) => {
    if (currentMilliseconds) {
      date._timeDifference = currentMilliseconds - new Date().getTime();
    }
  },

  /**
   * 根据有没有小数 对数据进行数据格式化
   * @param number
   * @returns {*}
   */
  fixedDecimal(number) {
    if (number) {
      if (String(number).indexOf('.') > 0) {
        if (typeof number === 'number') {
          return number.toString().substr(0, 5);
        }
        return number.substr(0, 5);
      }
      return number;
    }
    return 0;
  },
  /**
   * 格式化媒体时长
   * @param mss 毫秒数
   * @return {string} 用于显示的时长
   */
  formatDuring(mss) {
    let hours = Math.floor(mss / (1000 * 60 * 60));
    let minutes = Math.floor((mss % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((mss % (1000 * 60)) / 1000);
    hours = hours < 10 ? (`0${hours}`) : hours;
    minutes = minutes < 10 ? (`0${minutes}`) : minutes;
    if (seconds < 10 && seconds >= 1) {
      seconds = `0${seconds}`;
    } else if (seconds === 0) {
      seconds = '00';
    }
    return `${hours}:${minutes}:${seconds}`;
  },
};

export default date;
