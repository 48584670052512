import qr from 'qr-image';
import qs from 'qs';

let host = {
  picture: '/assets-view',
  video: '/assets-view',
};
if (window.settings && window.settings.host) {
  host = window.settings.host;
}
const utils = {
  host,
  isIOS: /iPhone|iPad|iPod|iOS|Mac OS/i.test(navigator.userAgent),
  isLocal: window.location.hostname === 'localhost',
  generateMixed(n) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return Array.from({ length: n }, () => alphabet[Math.ceil(Math.random() * (alphabet.length - 1))]).join('');
  },
  generateMixedNumber(n) {
    const library = '0123456789';
    return Array.from({ length: n }, () => library[Math.ceil(Math.random() * (library.length - 1))]).join('');
  },
  getImagePath(path) {
    if (!path) {
      return '';
    }
    if (path.includes('https://')) {
      return path;
    }
    if (!path.includes(utils.host.picture)) {
      return `${utils.host.picture}${path}`;
    }
    return path;
  },
  getVideoPath(path) {
    if (!path) {
      return '';
    }
    if (path.includes('https://')) {
      return path;
    }
    if (!path.includes(utils.host.video)) {
      return `${utils.host.video}${path}`;
    }
    return path;
  },
  getDocPath(path) {
    if (!path) {
      return '';
    }
    if (path.includes('https://')) {
      return path;
    }
    if (!path.includes(utils.host.doc)) {
      return `${utils.host.doc}${path}`;
    }
    return path;
  },
  getParam() {
    const params = { ...qs.parse(window.location.search.replace('?', '')), ...qs.parse(window.location.hash.substr(window.location.hash.lastIndexOf('?') + 1)) };
    return params;
  },
  /**
   * 二维码工具类
   */
  qrUtils: {
    generateImage(text) {
      return utils.qrUtils.imgBufferToBase64(qr.imageSync(text, {
        type: 'png',
        size: 8,
        margin: 3,
      }));
    },
    imgBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i]);
      }
      return `data:image/png;base64,${window.btoa(binary)}`;
    },
  },
  /**
   * 字符串转义解码
   * @param str
   */
  strHtmlDecode(str) {
    const targetStr = `${str}`;
    const txt = document.createElement('textarea');
    txt.innerHTML = targetStr;
    return txt.value;
  },
  /**
   * 字符串转义
   * @param str
   */
  strHtmlEncode(str) {
    const targetStr = `${str}`;
    return targetStr.trim().replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/ /g, '&nbsp;');
  },
  /**
   * 将textarea输入的数据转化成同格式html，并转义危险字符
   * @param str
   * @returns {*}
   */
  formatStr4textArea(str) {
    return this.strHtmlEncode(str).replace(/ /g, '&nbsp;').replace(/\n/g, '</br>');
  },
  /**
   * 将格式化后的textarea内容还原
   * @param str
   */
  decodeStr4textArea(str) {
    return this.strHtmlDecode(str).replace(/&nbsp;/g, ' ').replace(/<\/br>/g, '\n');
  },
  getGUID() {
    /**
     * @return {string}
     */
    function S4() {
      // eslint-disable-next-line no-bitwise
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (`${S4() + S4()}${S4()}${S4()}${S4()}${S4()}${S4()}${S4()}`);
  },
  getFileExt(filePath) {
    if (filePath?.substring) {
      return filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
    }
    return '';
  },
};
export default utils;
