<template>
  <div
    class="__video-player-box"
    v-loading="loading"
  >
    <d-player
      v-if="show"
      ref="player"
      class="d-player-custom-skin"
      :options="options"
    ></d-player>
  </div>
</template>

<script>
import VueDPlayer from 'vue-dplayer';
import 'vue-dplayer/dist/vue-dplayer.css';

export default {
  name: 'VideoPlayer',
  components: {
    'd-player': VueDPlayer,
  },
  props: {
    option: Object,
    onPlay: Function,
    onPause: Function,
    onEnded: Function,
  },
  data() {
    return {
      loading: false,
      show: true,
      hosts: [],
      currHost: '',
      options: {
        autoplay: this.option.autoplay,
        lang: 'zh-cn',
        mutex: true,
        hotkey: false,
        video: {
          // url: this.option.src || '',
          // 发现封面报错也会走 error 事件，先去掉封面设置
          // pic: this.option.pic,
        },
        contextmenu: [],
        danmaku: undefined,
      },
      currUrl: '',
      useLineIfNeed: true,
      showError: false,
      showLine: false,
      loadIndex: 0,
      pauseCount: 0,
      controlInterval: 0,
      exitFullScreenOnEnded: true,
      currRate: 1,
      timestamp: 0,
      // 前进后退的时间(单位秒)
      baseSeekSecond: 5,
    };
  },
  computed: {
    player() {
      return this.$refs.player.dp;
    },
  },
  watch: {
    option: {
      deep: true,
      immediate: true,
      handler() {
        if (Array.isArray(window.settings.host.videoHosts)) {
          this.hosts = [].concat(window.settings.host.videoHosts);
        }
        this.showError = false;
        this.currentHost = this.getNextHost();
      },
    },
  },
  mounted() {
    this.exitFullScreenOnEnded = this.option.exitFullScreenOnEnded || true;
    if (this.option.src) {
      this.switchVideo({
        url: this.option.src || '',
      });
    }
    this.controlInterval = setInterval(() => {
      if (this.pauseCount > 30) {
        window.location.reload();
      } else {
        this.pauseCount = 0;
      }
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.controlInterval);
    document.removeEventListener('keydown', this.documentOnKeyDown);
  },
  methods: {
    initEvent() {
      const self = this;
      const player = self.$refs.player.dp;
      player.on('ratechange', () => {
        this.currRate = player.video.playbackRate;
        this.activeRate(self.currRate);
      });
      player.on('play', () => {
        this.activeRate(self.currRate);
        if (window.instanceObj.currAudio && window.instanceObj.currAudio.pause) {
          window.instanceObj.currAudio.pause();
        }
        if (window.instanceObj.currVideo && window.instanceObj.currVideo !== player && window.instanceObj.currVideo.pause) {
          window.instanceObj.currVideo.pause();
        }
        window.instanceObj.currVideo = player;
        if (self.onPlay instanceof Function) {
          self.onPlay(player);
        }
      });
      player.on('pause', () => {
        self.pauseCount += 1;
        if (self.onPause instanceof Function) {
          self.onPause(player);
        }
      });
      player.on('ended', () => {
        if (self.exitFullScreenOnEnded) {
          player.fullScreen.cancel('browser');
        }
        if (self.onEnded instanceof Function) {
          self.onEnded(player);
        }
      });
      player.on('loadstart', () => {
        player.bar.set('played', 0, 'width');
      });
      player.on('canplay', () => {
        window.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'hidden' && player && player.pause && self.timestamp === player.timestamp) {
            player.pause();
          }
        });
      });
      player.on('error', () => {
        if (self.showLine) {
          if (player.prevVideo) {
            player.prevVideo.remove();
          }
          player.switchingQuality = false;
          clearTimeout(self.loadIndex);
          self.loading = false;
        }
        const nextHost = self.getNextHost();
        if (nextHost && !self.showLine) {
          player.video.src = player.video.src.replace(self.currentHost, nextHost);
        } else if (self.option.showError) {
          self.$message.error('资源已丢失，换个试试~');
          self.showError = true;
        }
      });
      player.on('quality_start', () => {
        self.initQualityActive();
        self.loading = true;
        clearTimeout(self.loadIndex);
        self.loadIndex = setTimeout(() => {
          if (player.prevVideo) {
            player.prevVideo.remove();
          }
          self.loading = false;
          player.switchingQuality = false;
        }, 3000);
      });
      player.on('quality_end', () => {
        clearTimeout(self.loadIndex);
        self.loading = false;
        self.currRate = 1;
        self.activeRate(1);
      });
      // 添加键盘按下事件
      document.addEventListener('keydown', self.documentOnKeyDown);
    },
    activeRate(rate) {
      rate = rate || 1;
      const player = this.$refs.player.dp;
      const activeItem = player.container.querySelector('.dplayer-setting-speed-item.active');
      const targetItem = player.container.querySelector(`.dplayer-setting-speed-item[data-speed="${rate}"]`);
      if (activeItem) {
        activeItem.className = 'dplayer-setting-speed-item';
      }
      if (targetItem) {
        targetItem.className = 'dplayer-setting-speed-item active';
      }
    },
    initQualityActive() {
      const player = this.$refs.player.dp;
      const activeItem = player.container.querySelector('.dplayer-quality-item.active');
      const targetItem = player.container.querySelector(`.dplayer-quality-item[data-index="${player.quality.index}"]`);
      if (activeItem) {
        activeItem.className = 'dplayer-quality-item';
      }
      if (targetItem) {
        targetItem.className = 'dplayer-quality-item active';
      }
      this.currUrl = player.quality.url;
    },
    hardReset() {
      const self = this;
      let player = self.$refs.player.dp;
      if (player && player.pause) {
        player.seek(0);
        player.video.pause();
        player.video.src = self.currUrl;
      }
      self.show = false;
      self.$nextTick(() => {
        self.show = true;
        self.$nextTick(() => {
          self.initEvent();
          setTimeout(() => {
            player = self.$refs.player.dp;
            player.timestamp = self.timestamp;
            self.activeRate(1);
            if (self.showLine && player && player.tran && !player._tran) {
              self.initQualityActive();
              player._tran = player.tran;
              player.tran = (e) => {
                if (e === 'quality') {
                  return '';
                }
                return player._tran(e);
              };
            }
          }, 100);
        });
      });
    },
    getNextHost() {
      if (this.hosts.length) {
        const next = this.hosts.shift();
        if (this.currHost === next) {
          return this.getNextHost();
        }

        return next;
      }
      return '';
    },
    switchVideo(option) {
      if (option.url && Array.isArray(window.settings.host.videoLineHost) && window.settings.host.videoLineHost.length) {
        const path = option.url.replace(window.settings.host.videoPlayerAndImg, '').replace(this.currentHost, '');
        const arr = [];
        let defaultQuality = 0;
        let includeDefault = false;
        window.settings.host.videoLineHost.forEach((item, index) => {
          const obj = {
            index,
            name: item.name || `线路${index + 1}`,
            url: `${item.host}${path}`,
          };
          if (window.settings.host.videoPlayerAndImg.includes(item.host)) {
            defaultQuality = index;
            includeDefault = true;
          }
          arr.push(obj);
        });
        if (!includeDefault) {
          arr.unshift({
            name: '默认线路',
            url: option.url,
          });
          arr.forEach((item, index) => {
            item.index = index;
          });
        }
        this.options.video.quality = arr;
        this.options.video.defaultQuality = defaultQuality;
        this.currUrl = this.options.video.quality[0].url;
        this.showLine = true;
      } else {
        this.options.video.url = option.url || '';
        this.currUrl = this.options.video.url;
        if (this.$refs.player.dp.quality) {
          this.$refs.player.dp.quality.url = '';
        }
      }
      this.timestamp = +new Date();
      this.hardReset();
    },
    /**
     * 文档监听键盘按下事件
     * @author create 2021/5/31 王求送
     * @param event 事件
     */
    documentOnKeyDown(event) {
      const self = this;
      const e = event || window.event;
      // 处理当前播放器实例，多个播放器取当前vue实例记录的播放器
      let playerInstance = null;
      const videoNodeList = document.querySelectorAll('video');
      if (videoNodeList.length) {
        if (videoNodeList.length > 1) {
          playerInstance = window.instanceObj.currVideo;
        } else {
          playerInstance = self.$refs.player.dp;
        }
      }
      switch (e.keyCode) {
        case 37: // 键盘方向键左键
          e.preventDefault();
          self.backward(playerInstance);
          break;
        case 39: // 键盘方向键右键
          e.preventDefault();
          self.forward(playerInstance);
          break;
        default:
          break;
      }
    },
    /**
     * 前进
     * @author create 2021/6/1 王求送
     * @param player 播放器实例
     */
    forward(player) {
      const self = this;
      if (player) {
        // 当前时间
        let current = 0;
        // 视频总时长
        let videoDuration = 0;
        if (player.video) {
          current = player.video.currentTime || 0;
          videoDuration = player.video.duration || 0;
        }
        const tmpSeekTime = current + self.baseSeekSecond;
        const seekTime = tmpSeekTime <= videoDuration ? tmpSeekTime : videoDuration;
        self.seekAndPlayByPlayer(player, seekTime);
      }
    },
    /**
     * 后退
     * @author create 2021/6/1 王求送
     * @param player 播放器实例
     */
    backward(player) {
      const self = this;
      if (player) {
        // 当前时间
        let current = 0;
        if (player.video) {
          current = player.video.currentTime || 0;
        }
        const tmpSeekTime = current - self.baseSeekSecond;
        const seekTime = tmpSeekTime >= 0 ? tmpSeekTime : 0;
        self.seekAndPlayByPlayer(player, seekTime);
      }
    },
    /**
     * 跳转指定时间并播放
     * @param player 播放器实例
     * @param seekTime 当前跳转时间
     */
    seekAndPlayByPlayer(player, seekTime) {
      if (player) {
        if (!Number.isNaN(seekTime) && player.seek) {
          player.seek(seekTime);
        }
        if (player.video && player.video.paused && player.video.play) {
          player.video.play();
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .__video-player-box {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    .dplayer-setting-speed-item.active:before {
      position: absolute;
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: $success;
      top: 14px;
      left: 10px;
      margin-right: 3px;
    }
    .dplayer-quality-item.active:before {
      position: relative;
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: $success;
      top: -2px;
      margin-right: 3px;
    }
    .d-player-custom-skin {
      height: 100%;
      .dplayer-menu {
        display: none !important;
      }
      .dplayer-setting-loop {
        display: none !important;
      }
      .dplayer-controller .dplayer-icons .dplayer-full .dplayer-full-in-icon {
        display: none !important;
      }
    }
  }

</style>
